import { HomePage } from './pages'

function App() {
  return (
    <>
      <HomePage />
    </>
  )
}

export default App
